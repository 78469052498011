.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(194, 221, 237);
  height: 100vh;
}

.App-header {
  font-size: calc(10rem + 2vmin);
  color: white;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
}


 .aine-text {
      animation: slideIn 1.5s ease-in-out forwards;
    }

  .media-text {
    color: white;
    animation: slideout 1.5s ease-in-out backwards;
  }

@keyframes slideIn {
  0% {
    transform: translateY(-200%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideout {
  0% {
    transform: translateY(500%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}



